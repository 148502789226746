import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home_view"), // [로드 파일]
  },
  {
    path: "/sub",
    name: "sub",
    component: () => import("../views/Salary_calculation_view"), // [로드 파일]
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login_view"), // [로드 파일]
  },
  {
    path: "/salaryList",
    name: "salaryList",
    component: () => import("../views/salary/Salary_list_view"), // [로드 파일]
  },
  {
    path: "/salaryDetail",
    name: "salaryDetail",
    component: () => import("../views/salary/Salary_detail_view"), // [로드 파일]
  },
  {
    path: "/userpage",
    name: "userpage",
    component: () => import("../views/user/User_page_view"), // [로드 파일]
    props: true,
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("../views/Admin_view"), // [로드 파일]
    props: true,
  },
  {
    path: "/useradd",
    name: "useradd",
    component: () => import("../views/User_add_view"), // [로드 파일]
    props: true,
  },
  {
    path: "/exceluploadview",
    name: "exceluploadview",
    component: () => import("../views/Excel_upload_view"), // [로드 파일]
  },
  {
    path: "/national_pension",
    name: "national_pension",
    component: () => import("../views/National_pension_check_view"), // [로드 파일]
  },
  {
    path: "/health_insurance",
    name: "health_insurance",
    component: () => import("../views/Health_insurance_check_view"), // [로드 파일]
  },
  {
    path: "/income_tax_check",
    name: "income_tax_check",
    component: () => import("../views/Income_tax_check_view"), // [로드 파일]
  },
  {
    path: "/salary_check",
    name: "salary_check",
    component: () => import("../views/Salary_check_view"), // [로드 파일]
  },
];

// 라우터 생성
const router = createRouter({
  // history: createWebHistory(),
  history: createWebHashHistory(),
  routes,
});

export { router };
