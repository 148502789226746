import { createApp } from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
// 생성한 뷰 라우터 받아오기
import { router } from "./router/index.js";
import axios from "axios";
import vueCookies from "vue-cookies";
import { server, formatDate } from "./config/config.js";

const app = createApp(App);

app.config.globalProperties.$cookies = vueCookies;
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$server = server;
app.config.globalProperties.$formatDate = formatDate;
// app.config.globalProperties.$router = router;
app.use(router); // 라우터 사용
app.use(vueCookies);
app.use(vuetify);
app.mount("#app");
