import dayjs from "dayjs";

// export const server = "http://localhost:8089/";
export const server = "http://172.30.1.20:33066/paystub_db/";
// 172.30.1.20:33066/paystub_db
// export o

// date format
export const formatDate = (date) => {
  return dayjs(date).format("YYYY-MM-DD");
};

export const amount = (a) => {
  return parseInt(a).toLocaleString();
};

export const login_user = () => {
  const user = localStorage.getItem("user");
  return JSON.parse(user);
};
